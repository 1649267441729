import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Dialog,
    DialogContent,
    Grid,
    Select,
    MenuItem,
    TextField,
    OutlinedInput,
    Divider
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
interface FormValue {
    investmentAmount: string;
    currencyName: string;
    investmentDuration: string;
    selectPeriod: string;
    selectPeriodAr:string;
  }

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


// Customizable Area End

import InteractivecalculatorController, {
    Props,
    configJSON,
} from "./InteractivecalculatorController";

export default class Interactivecalculator extends InteractivecalculatorController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
 
      
      
    
   
      

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
           
            <StyledDialog
            open={this.state.open}
            onClose={this.props.handleClose}
            
            
            >
                 <MainWrapper>
                <DialogContent className="dialogContent">
                <Formik 
                        data-test-id="formik"
                        enableReinitialize
                        initialValues={{
                            investmentAmount:'',
                            currencyName:'Omani Rial',
                            investmentDuration:'',
                            selectPeriod:'',
                            selectPeriodAr: ''
                        }}
                        validationSchema={this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', this.userSchemaAr , this.userSchema)}
                        onSubmit={(values: FormValue) => {
                            this.handleFormValue(values)
                        }}
                        >
                            {({
                                handleSubmit,
                                errors,
                                values,
                                handleBlur,
                                touched,
                                setFieldValue,
                                resetForm
                            }) => (
                               
                            <form
                            onSubmit={handleSubmit}
                            noValidate
                            autoComplete="off"
                            >
                      <Box style={{
                        ...webStyle.upperBox,
                         direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl' , 'ltr'),
                         width:this.handleCondition(this.props.i18n?.language === 'ar', "97%", "100%" )
                         }}>
                        <Box >
                            <Typography className="upperBoxTypo">
                            {this.props.i18n?.t('calculator')}
                            </Typography>
                        </Box>
                        <Box className="closeButton" data-test-id="handleClose"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {resetForm(); this.props.handleClose(); this.handleClose();}}>
                            <img src={this.state.iconClose} alt='close'/>
                        </Box>
                      </Box>
                   <Divider style={{marginTop:'16px',marginLeft: '-24px',marginRight: '-24px'}}/>
                <Box className="formBox" style={{...webStyle.styleBox, direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl' , 'ltr')}}>
                   
                   <Box>
                      <Box>
                        <Box>
                            <Typography style={{...webStyle.styleBox, direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl' , 'ltr')}}>{this.props.i18n?.t('profitCalculator')}</Typography>
                        </Box>
                        <Box className="formikMainBox">
                     
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className="inputBox">
                                            <Typography style={{...webStyle.inputBoxTypo, direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl' , 'rtl'),
                                             textAlign: this.handleCondition(this.props.i18n?.language === 'ar', '', 'left')}}>
                                                {this.props.i18n?.t('investAmount')}
                                            </Typography>
                                            <TextField
                                            data-test-id="txtInputInvestmentAmount"
                                            name="investmentAmount"
                                            placeholder={this.props.i18n?.t('enterAmount')}
                                            fullWidth
                                            onBlur={handleBlur}
                                            value={values.investmentAmount}
                                            style={{
                                                ...webStyle.inputStyle,
                                                border: this.handleCondition(this.getErrorMessage(touched, errors, 'investmentAmount') , '1px solid #F87171' , '1px solid #ced4da')
                                              }}
                                            onChange={(event) => {
                                                const numericValue = event.target.value.replace(/\D/g,"");
                                                setFieldValue("investmentAmount", numericValue);
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: "textinvestmentAmountFieldRoot",
                                                },
                                                disableUnderline:true,
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <Select
                                                     style={{direction: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'rtl')}}
                                                    value={values.currencyName}
                                                    onChange={(event) => setFieldValue("currencyName", event.target.value)}
                                                    className=""
                                                    disableUnderline
                                                    data-test-id="currencyName">
                                                         <MenuItem value="Omani Rial">OMR</MenuItem>
                                                        {configJSON.selectCurrency.map((object:{ value: string; label: string }, index:number) => (
                                                            <MenuItem key={index} value={object.value}>
                                                              {object.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </InputAdornment>
                                                ),
                                            }}
                                            />
                                        {this.getErrorMessage(touched, errors, "investmentAmount")}
                                        </Box>
                                    </Grid>
                                    <Grid className="investmentTypoGrid" item xs={12} sm={12} md={12} lg={12}>
                                    <Typography style={{...webStyle.inputBoxTypo, direction: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'rtl'),
                                     textAlign: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', '', 'left')}}>
                                            {this.props.i18n?.t('investmentDuration')}
                                            </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <Box className="inputBox">
                                            <TextField
                                            data-test-id="investDuration"
                                            name="investmentDuration"
                                            placeholder={this.props.i18n?.t('enterNumber')}
                                            fullWidth
                                            onBlur={handleBlur}
                                            value={values.investmentDuration}
                                            style={{
                                                ...webStyle.inputStyle,
                                                border: this.handleCondition(this.getErrorMessage(touched, errors, 'investmentDuration') , '1px solid #F87171' , '1px solid #ced4da')
                                              }}
                                            onChange={(event) => {
                                                const numericValue = event.target.value.replace(/\D/g,"");
                                                setFieldValue("investmentDuration", numericValue);
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: "textinvestmentDurationFieldRoot",
                                                },
                                                disableUnderline:true,
                                            }}
                                            />
                                        {this.getErrorMessage(touched, errors, "investmentDuration")}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="selectDurationGrid">
                                        <Box className="inputBoxSelectDuration" style={{direction: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}}>
                                            <Select
                                            variant="outlined"
                                            style={{...webStyle.inputStyle2,
                                                 border: this.handleCondition(this.getErrorMessage(touched, errors, 'selectPeriod'), '1px solid #F87171' , '1px solid #ced4da')}}
                                            name="selectPeriod"
                                            fullWidth
                                            ref={this.state.selectRef}
                                            placeholder={this.props.i18n?.t('selectDuration')}
                                            data-test-id="txtInputSelectPeriod"
                                            value={values.selectPeriod}
                                            onBlur={handleBlur}
                                            onChange={(event) =>
                                                 setFieldValue("selectPeriod", event.target.value)
                                                }
                                            className={`selectRoot ${this.handleCondition(this.props.i18n && this.props.i18n.language === "ar" , "arabic" , "english")}`}
                                            displayEmpty
                                            input={<OutlinedInput />}
                                            renderValue={(selected: unknown) => {
                                                if (!selected) {
                                                    return (
                                                        <span className="spanSelect" style={{direction:"ltr"}}>
                                                            {this.props.i18n?.t('selectDuration')}
                                                        </span>
                                                    );
                                                }
                                                return selected as string | JSX.Element;
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '61px',
                                                        width: this.state.selectWidth,
                                                        borderRadius:'8px'
                                                    },
                                                },
                                                MenuListProps: {
                                                    style: {
                                                        paddingTop: '0',
                                                        paddingBottom: '0',
                                                    },
                                                },
                                            }}
                                            >
                                                {this.handleCondition(this.props.i18n && this.props.i18n.language !== 'ar', selectPeriod , selectPeriodAr).map((object:{ value: string; label: string }, index:number) => (
                                                <CustomMenuItem
                                                key={index}
                                                value={object.value}
                                                style={{direction: this.handleCondition( this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}}
                                                >
                                                    
                                                    {object.label}
                                                </CustomMenuItem>
                                                ))}
                                            </Select>
                                            {this.getErrorMessage(touched, errors, 'selectPeriod')}
                                        </Box>
                                    </Grid>
                                </Grid>
                        </Box>
                      </Box>
                   </Box>
                   <Box>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className="lowerBoxMain">
                            <Box>
                                <Typography className="lowerBoxTypo"
                                  style={{direction: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'rtl'),
                                   textAlign: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', '', 'left')}}
                                  >
                                  {this.props.i18n?.t('returns')}
                                </Typography>
                            </Box>
                            <Box className="resultBox">
                                <Box><Typography className="resultBoxTypo">{this.state.result}</Typography></Box>
                            </Box>
                        </Box>
                  </Grid>
                   </Box>
                </Box>   
                <Box className="bottomBoxMain">
                    <Box className="bottomBox"  style={{direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl' , 'ltr')}}>
                        <Box>
                          <Button type="button" data-test-id="resetForm" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {resetForm(); this.clearResultState()}} className="buttonClear"><Typography className="buttonClearTypo">{this.props.i18n?.t('clear')}</Typography></Button>
                        </Box>
                        <Box>
                          <Button type="submit" className="buttonDone"><Typography className="buttonDoneTypo">{this.props.i18n?.t('done')}</Typography></Button>
                        </Box>
                    </Box>
                </Box>
                </form>
                            )}
                        </Formik> 
                </DialogContent>
               </MainWrapper>
            </StyledDialog>
            
           
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    inputStyle:
    {border:"1px solid #E2E8F0", padding:"10px 8px", textDecoration:"none", borderRadius:"8px", paddingLeft:"1rem", width:"calc(100% - 16px)"},
    inputStyle2:
    {border:"1px solid #E2E8F0", textDecoration:"none", borderRadius:"8px", paddingLeft:"1rem", width:"100%",},
    styleBox: {
        paddingTop: "10px"
    },
    upperBox:{
        display: 'flex',justifyContent: 'space-between'
    },
    inputBoxTypo:{
        color:'#64748B',        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '14px',        fontWeight: 700,
        lineHeight: '22px'
    },
    middleBoxTypo:{        color : '#0F172A',
        fontFamily: 'DIN Next LT Arabic Regular',
                fontSize: '16px',        fontWeight: 400,        lineHeight: '24px',        textAlign: 'left',
    },
    follow:{
        color:'#64748B',        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '14px',        fontWeight: 700,
        lineHeight: '22px',
    },
    getor:{        color : '#0F172A',
        fontFamily: 'DIN Next LT Arabic Regular',
                fontSize: '16px',        fontWeight: 400,        lineHeight: '24px',        textAlign: 'left',
    },
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        backgroundColor: 'white',
        borderRadius: '8px',
        [theme.breakpoints.down('xs')]:{
            borderRadius: '8px 8px 32px 8px',

        },
      
    },
}));

const selectPeriod = [{ label: "Month", value: "month" },{ label: "Year", value: "year" }];
const selectPeriodAr = [{ label: "شهر", value: "شهر" },{ label: "سنة", value: "سنة" }];

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    color: '#292524',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    
    '&:hover': {
      backgroundColor: '#1B4FE4',
      color: '#FFFFFF',
    },
    "&.Mui-selected":{
        backgroundColor: '#1B4FE4',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#1B4FE4',
            color: '#FFFFFF',
      },

    }
  }));

const MainWrapper = styled(Box)(({ theme }) => ({
    "& .MuiList-padding":{

        padding:"0px !important",
    },
    "& .dialogContent": {
        gap: '8px',
        display: 'flex',
        flexDirection: 'column',
        
    },

    "& .arabic": {
        "& .MuiSelect-iconOutlined":{
            right:  "85%"
        },
    },
    "& .english": {
        "& .MuiSelect-iconOutlined":{
            right:  "7px"
        },
    },
    "& .upperBoxTypo":{
        color:'#000000',
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        marginLeft: '18px',
        [theme.breakpoints.down('xs')]:{
            color:'#1E293B',
            marginLeft: '5px'
        }
    },
    "& .inputBox":{
        display:'flex',
        flexDirection:'column',
        gap:'2px',
        [theme.breakpoints.down('xs')]:{
            marginTop:'-5px'
        }
    },
    "& .textInvestmentAmountFieldRoot":{ display:"flex", gap:"12px",
        "& .MuiOutlinedInput-input": {
      padding: '0px',
      borderRadius:'8px',textAlign: 'start',
      fontFamily: 'DIN Next LT Arabic Regular',fontSize: '16px',
      fontWeight: 400,lineHeight: '24px',
      color:'#292524',[theme.breakpoints.down('xs')]:{
        padding: '16.5px 8px',
      }
     
    },
    "&.MuiOutlinedInput-root":{position:'relative',borderRadius:'8px',},
    "& .MuiInputBase-root":{margin: '12px 1px 4px 0px'
    },"& .MuiSelect-select.MuiSelect-select":{
      marginBottom: '7px',backgroundColor: 'rgb(239, 246, 255)',
      color:'#1E293B',fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
      paddingBottom: '4px',
      paddingTop: '4px',
      paddingLeft: '11px',
      marginLeft: '-7px',
      borderRadius: '2px',
    },
   
    "& .MuiSelect-icon":{top: 'calc(50% - 14px)',color: 'rgba(0, 0, 0, 0.54)',right: 0,position: 'absolute',
      pointerEvents: 'none'
    },
    
        
    },
    "& .textInvestmentDurationFieldRoot":{"& .MuiOutlinedInput-input": {
        padding:"0px",
      borderRadius:'8px',textAlign: 'start',
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',fontWeight: 400,
      lineHeight: '24px',color:'#292524',
      [theme.breakpoints.down('xs')]:{padding: '16.5px 8px',
      }
     
    },"&.MuiOutlinedInput-root":{position:'relative',borderRadius:'8px',},
    "& .MuiInputBase-root":{margin: '12px 1px 4px 0px'},
    "& .MuiSelect-select.MuiSelect-select":{marginBottom: '7px',
      backgroundColor: 'rgb(239, 246, 255)',color:'#1E293B',
      fontFamily: 'DIN Next LT Arabic Regular',fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'left',
      marginLeft: '-7px',
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '4px',
      borderRadius: '2px',
    },
   
    "& .MuiSelect-icon":{top: 'calc(50% - 14px)',color: 'rgba(0, 0, 0, 0.54)',right: 0,position: 'absolute',
      pointerEvents: 'none'
    },
    
    },
    "& .selectRoot .MuiOutlinedInput-input": {
        textAlign: 'start',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '14px',
        color:'#292524',
        padding:"14px 8px",
        [theme.breakpoints.down('xs')]:{
            padding: '14.0px 20% 14.0px 8px',
          }
       
      },
      "& .selectRoot .MuiSelect-outlined":{
        position:'relative',
        borderRadius:'8px',
      },
      "& .selectRoot.MuiOutlinedInput-root":{
        borderRadius: '8px'
      },
  
    "& .upperBox":{
        display: 'flex',
        justifyContent: 'space-between'

    },
    "& .formikMainBox":{
        marginTop:'16px'
    },
    "& .resultBox":{        marginTop:'5px',
        border:'1px solid #CBD5E1',        height:'56px',
        borderRadius:'8px',
        display:'flex',        justifyContent:'center',
        alignItems:'center'    },
    "& .lowerBoxTypo":{
        color:'#64748B',
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',

    },
    "& .bottomBox":{
        display:'flex',
        justifyContent:'end',
        gap:'16px',
        [theme.breakpoints.down('xs')]:{
            marginTop:'10px',
            marginBottom:'-16px'

        },
       
        
    },
    "& .buttonClear":{
        width: '120px',
        height: '56px',
        padding: '16px',
        gap: '8px',
        borderRadius: '8px',
        border :'1.5px solid #1B4FE4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]:{
            height: '44px',
            width:'90px'
        }
    },
    "& .buttonDone":{
        backgroundColor:'#1B4FE4',
        width: '120px',
        height: '56px',
        padding: '16px',
        gap: '8px',
        borderRadius: '8px',
        border :'1.5px solid #1B4FE4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]:{
            height: '44px',
            width:'90px'
        }
    },
    "& .buttonClearTypo":{
        color:'#1B4FE4',
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform:'none'

    },
    "& .buttonDoneTypo":{
        color:'#FFFFFF',
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform:'none'

    },
    "& .bottomBoxMain":{
        marginBottom: '15px',
        [theme.breakpoints.down('xs')]:{
            marginBottom: '5px'

        }

    },
    "& .formBox":{
        padding:'0px 20px 20px 20px',
        [theme.breakpoints.down('xs')]:{
            padding:'0px 5px 5px 5px',
        }

    },
    "& .lowerBoxMain":{
        [theme.breakpoints.down('xs')]:{
            marginTop:'3px'

        }
      

    },
    "& .selectDurationGrid":{
        [theme.breakpoints.down('xs')]:{
          marginTop:'-4px'

        }
    },
    '& .MuiList-root':{
        padding:"0 !important",
        background:"red"

    },
    // "& .inputBoxSelectDuration":{
    //     display:'flex',
    //     flexDirection:'column',
    //     gap:'2px',
    // },
    "& .closeButton":{
        cursor:'pointer'

    },
    "&. resultBoxTypo":{
        color:'#292524',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center',
    },
    "& .spanSelect":{
        color: '#94A3B8',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        // textAlign: 'left',
    },
    "& .investmentTypoGrid":{
        marginTop:'0px',
        marginBottom:'-13px',
        [theme.breakpoints.down('xs')]:{
            marginTop:'-10px',
            marginBottom:'-10px'
        }
    }
}))

export const InteractiveCalWeb = withTranslation()(Interactivecalculator)
// Customizable Area End
