// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  styled,
  createTheme,
  Dialog,
  DialogContent,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import {  } from "@material-ui/styles";
import { Form, Formik,} from "formik";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import CustomFormController, {
  Props,
  Touched,
  Error,
  FormErrors,
} from "./CustomFormController.web";
import { Divider } from "react-native-elements";
import Spinner from "./components/Spinner.web";
import { applyBlueColor, numberToWords } from "../../../components/src/utils.web";
import { withTranslation } from "react-i18next";
import { imgArrow2, imgUploadBoxLargeA } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
renderErrorBox =(Condition:boolean)=>{
  return(
    this.handleCondition(Condition, 
      <Box 
      className="error_box2"
      style={{
        borderLeft:
         this.handleCondition(this.handleCondition(this.props.i18n, this.props.i18n.language, "") === 'ar',
         "",
          "4px solid var(--Status-Red-600, #DC2626)"),
        borderRight:         this.handleCondition(this.handleCondition(this.props.i18n, this.props.i18n.language, "") === 'ar',
          "4px solid var(--Status-Red-600, #DC2626)", ""),
        justifyContent:   this.handleCondition(this.handleCondition(this.props.i18n, this.props.i18n.language, "") === 'ar', "flex-end","flex-start")
      }}
      >
        <Box className="error_alert_box">
          <Typography className="error_alert_box_style">
          {this.handleTranslationChange('plz_agree_T&C')}
          </Typography>
        </Box>
    </Box>
     , 
      ""
    )

  )}
renderErrorBoxSign = (Condition:boolean)=>{

    return(
      Condition? (
        <Box 
        className="error_box">
          <Box className="error_alert_box">
            <Typography className="error_alert_box_style">
            {this.handleTranslationChange('plz_add_E_sign')}
            </Typography>
          </Box>
      </Box>
      ) : (
        ""
      )
  
    )}
    renderErrorBoxForInput = (errors: FormErrors) => {
      const errorMessages = Object.values(errors);
      if (errorMessages.length === 0) return null;
  
      return (
        <Box className="error_box">
          <Box className="error_alert_box">
            {errorMessages.map((error, index) => (
              <Typography key={index} className="error_alert_box_style">
                {error}
              </Typography>
            ))}
          </Box>
        </Box>
      );
    };

    renderContent = (part: string, contractAmount: string, handleInputChange: (e: any, field: any) => void, amount: string) => {
      const placeholder = '[CONTRACT_AMOUNT]';
      const amountOfPlaceholder = '[CONTRACT_AMOUNT_TEXT]';
      const totalAmount = '[TOTAL_AMOUNT]';
      
      // Convert the contract amount to words and wrap it in a span with color
      const coloredAmountInWords = this.handleCondition(contractAmount 
        , `<span style="color: blue;">${numberToWords(Number(contractAmount)).english}</span>`
        , '');
      
      const coloredCurrenty = part?.replace('OMR', applyBlueColor('OMR'))
    
      // Replace [CONTRACT_AMOUNT_TEXT] with the colored word representation of the contract amount
      const processedPart = coloredCurrenty.replace(amountOfPlaceholder, coloredAmountInWords);
    
      // Split the processed part by [CONTRACT_AMOUNT] to handle input replacement
      const parts = processedPart.split(placeholder);
      const secondParts = part?.split(totalAmount);
      return (
        <>
          {this.handleCondition(part.indexOf(totalAmount) === -1 , parts.map((subPart: string, subIndex: number) => (
            <React.Fragment key={subIndex}>
              <Typography
                className="text"
                component="span"
                dangerouslySetInnerHTML={{ __html: subPart }}
              />
              {this.handleCondition(subIndex < parts.length - 1, (
                <input
                  data-test-id="amountInput"
                  id='pdf-content-input'
                  type="text"
                  autoComplete="off"
                  className="customInput"
                  style={{color: 'blue'}}
                  value={contractAmount}
                  onChange={(e) => handleInputChange(e, 'contractAmount')}
                  placeholder="Enter amount"
                  lang="ar"
                  dir="auto"
                />
              ), "")}
            </React.Fragment>
          )), secondParts.map((subPart: string, subIndex: number) => (
            <React.Fragment key={subIndex}>
              <Typography
                className="text"
                component="span"
                dangerouslySetInnerHTML={{ __html: subPart }}
              />
              {this.handleCondition(subIndex < secondParts.length - 1 , (
                <input
                  disabled
                  id='pdf-content-input'
                  type="text"
                  autoComplete="off"
                  className="customInput"
                  style={{color: 'blue'}}
                  value={amount}
                  placeholder="Deposited Amount"
                  lang="ar"
                  dir="auto"
                />
              ), "")}
            </React.Fragment>
          )))}
        </>
      );
    };
    
    
    renderContentValue = (part: string, contractAmount: string = '', totalAmount: string = "") => {
      let amountInWords = '';
      let coloredContractAmount = '';
      let coloredTotalAmount = "0";
    
      if (contractAmount) {
        amountInWords = numberToWords(Number(contractAmount)).arabic;
        coloredContractAmount = `<span style="color: blue;">${contractAmount}</span>`;
      }

      if (totalAmount) {
        coloredTotalAmount = `<span style="color: blue;">${totalAmount}</span>`;
      }
    
      const coloredAmountInWords = `<span style="color: blue;">${amountInWords}</span>`;
      
      let replacedHtml = part;
      replacedHtml = replacedHtml?.replace('1.9', applyBlueColor('1.9'))
      replacedHtml = replacedHtml?.replace(/\[CONTRACT_AMOUNT_TEXT\]/g, coloredAmountInWords);
      replacedHtml = replacedHtml?.replace(/\[CONTRACT_AMOUNT\]/g, coloredContractAmount);
      replacedHtml = replacedHtml?.replace(/\[TOTAL_AMOUNT\]/g, coloredTotalAmount);
      
      return (
        <Typography
          component="div"
          className={`${this.state.isArabic ? 'rtl-text' : 'ltr-text'} text`}
          dangerouslySetInnerHTML={{ __html: replacedHtml }}
        />
      );
    }
    ImgBox = styled(Box)(({ theme }) => ({
      "& .imgIcon": {
        [theme.breakpoints.down(985)]: {
          content: `url(${this.state.imgIconSmall})`,
        },
        [theme.breakpoints.up(985)]: {
          content: `url(${this.state.imgIcon})`,
        },
      },
      "& .imgAddress": {
        [theme.breakpoints.down(985)]: {
          content: `url(${this.state.imgAddressSmall})`,
        },
        [theme.breakpoints.up(985)]: {
          content: `url(${this.state.imgAddress})`,
        },
      },
    }));
  currentDate = new Date();
  day = String(this.currentDate.getDate()).padStart(2, '0');
  month = String(this.currentDate.getMonth() + 1).padStart(2, '0');
  year = this.currentDate.getFullYear();

  formattedDate = `${this.day}-${this.month}-${this.year}`;
  isArabic = this.props.i18n?.language === 'ar';
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <MainWrapper>
      <Box>
      {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
      <div className="scrollableContainer">
      <div style={{ width: '1650px', minWidth: '1200px', margin: '0 auto'}}>
        {this.state.contractContent &&<Grid container spacing={0}>
      
        <Grid item xs={12} sm={12} className="grid">
        <Box
  data-test-id="navigateToSignUpPage_term"
  onClick={this.navigateToDashBoardPage}
  sx={{
    [theme.breakpoints.up("xs")]: {
      backgroundImage: `url(${this.handleCondition(this.props.i18n?.language === 'ar', imgArrow2, this.state.imgArrow)})`,
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down(985)]: {
      backgroundImage: `url(${this.handleCondition(this.props.i18n?.language === 'ar', imgArrow2 , this.state.imgArrowSmall)})`,
      backgroundRepeat: 'no-repeat',
    },
  }}
  className="arrowButton"
  style={{
    marginLeft: this.handleCondition(this.props.i18n?.language === 'ar', '90%', "135px"),
  }}
>
</Box>
        
          <Box  className="mainBox">
         
         
            <Box  id='pdf-content' className="innerBox">
            <Box>
            <Grid container spacing={3} style={{justifyContent:'space-between'}}>
              <Grid item xs={4} sm={4} md={3} className="gridIconFirst">
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <Box display="flex" justifyContent="center">
                 <this.ImgBox>
                 <img src={this.handleCondition(this.state.width>985, this.state.imgIcon, this.state.imgIconSmall)} alt="Icon" />
                 </this.ImgBox>
                </Box>
              </Grid>
              <Grid item xs={4} sm={4} md={3} className="addressGrid">
                <Box display="flex" justifyContent="flex-end">
                 <this.ImgBox style={{display:'flex'}}>
                 <img src={this.handleCondition(this.state.width>985, this.state.imgAddress, this.state.imgAddressSmall)} alt="Icon" />
                 </this.ImgBox>
                </Box>
             </Grid>
            </Grid>
            </Box>

              <Typography className="titleTwo">Al Maimani Wealth Management Investment Contract</Typography>
              <Typography className="titleTwo">عقد اتفاق إدارة الثروات</Typography>
              
              <Grid container spacing={0}>
              <Grid item xs={12} sm={6} md={6} lg={6}>  
              <Box className="upperBox">
                <Box><Typography className="upperTitleTypo">Between the parties below:</Typography></Box>
                <Box className="upperTitleBox"><Typography className="upperTitleTypo">First Party:&nbsp;</Typography><Typography  className="upperTitleTypoBold">Abdulwahab Abdullah Al Maimani</Typography></Box>
                <Box className="upperTitleBox"><Typography className="upperTitleTypo">Second Party:&nbsp;</Typography ><Typography  className="upperTitleTypoBold">
                  <input
                    data-test-id="secondPartyEnglish"
                    id='pdf-content input'
                    className="customInput"
                    type="text"
                    autoComplete="off"
                    value={this.state.second_party_english}
                    onChange={(e) => this.handleInputChange(e, 'second_party_english')}
                    placeholder="Enter second party"
                    dir="ltr"
                  />
                  </Typography></Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className="arabicUpperTitleGrid">  
              <Box className="upperBox">
                 <Box><Typography className="upperTitleTypo">فيما بين الموقعين أدناه:</Typography></Box>
                <Box className="upperTitleBox"><Typography className="upperTitleTypo">الطرف األول:</Typography><Typography  className="upperTitleTypoBold">عبدالوهاب بن عبدالله الميمني</Typography></Box>
                <Box className="upperTitleBox"><Typography className="upperTitleTypo">الطرف الثاني:</Typography ><Typography  className="upperTitleTypoBold">
                  <input
                    data-test-id="secondPartyArabic"
                    id='pdf-content input'
                    className="customInput"
                    type="text"
                    autoComplete="off"
                    value={this.state.second_party_arabic}
                    onChange={(e) => this.handleInputChange(e, 'second_party_arabic')}
                    placeholder="أدخل الطرف الثاني"
                    lang="ar"
                    dir="rtl"
                  />
                  </Typography></Box>
                </Box>
            </Grid>
            </Grid>
          
                  <Formik
                    data-test-id="formik"
                    initialValues={this.state.initialValues}
                    validationSchema={this.state.validationSchema}
                    onSubmit={values => this.submitForm(values)}
                  >
                    {({ handleSubmit, values, errors, touched, setFieldValue, setErrors, setTouched }) => (
                      <Form onSubmit={handleSubmit} className="form">


                        <Grid container spacing={0} className="contentMainGrid">
                          {this.state.contentEnglish.map((part, index) => (
                            <React.Fragment key={index}>



                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                {index === 0 && (
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Typography style={{
                                      fontSize: '18px',
                                      fontFamily: 'DIN Next LT Arabic Bold',
                                      fontWeight: 700,
                                      lineHeight: '26px',
                                      textAlign: 'left',
                                      marginBottom: '6px'
                                    }}>Preface</Typography>
                                  </Grid>
                                )}
                                <Box>

                                  {this.renderContent(part, this.state.contractAmount, this.handleInputChange, this.state.totalAmount)}
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} style={{ direction: 'rtl', textAlign: 'right' }}>
                                {index === 0 && (
                                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ direction: 'rtl', textAlign: 'right' }}>
                                    <Typography style={{
                                      fontSize: '18px',
                                      fontFamily: 'DIN Next LT Arabic Bold',
                                      fontWeight: 700,
                                      lineHeight: '26px',
                                      marginBottom: '8px'
                                    }}>التمهيد
                                    </Typography>
                                  </Grid>
                                )}
                                <Box>
                                  {/* <Typography style={{textAlign: 'right'}} className="text" dangerouslySetInnerHTML={{ __html: this.state.contentArabic[index] }} /> */}
                                  {this.renderContentValue(this.state.contentArabic[index], this.state.contractAmount, this.state.totalAmount)}

                                </Box>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>



                        <Box className="lowerBox">
                          <Box className="lowerBoxSecond">
                            <Box><Typography className="lowerBoxTitleTypo">Bank Name:&nbsp;</Typography></Box>
                            <Box><Typography className="lowerBoxTitleTypoBold">{this.state.bankDetails.bank_name}</Typography></Box>
                          </Box>
                          <Box className="lowerBoxSecond">
                            <Box className="lowerBoxTitle"><Typography className="lowerBoxTitleTypo">Beneficiary Name:&nbsp;</Typography></Box>
                            <Box className="lowerBoxTitle"><Typography className="lowerBoxTitleTypoBold">{this.state.bankDetails.account_holder_name}</Typography></Box>
                          </Box>
                          <Box className="lowerBoxSecond">
                            <Box className="lowerBoxTitle"><Typography className="lowerBoxTitleTypo">Account Number:&nbsp;</Typography></Box>
                            <Box className="lowerBoxTitle"><Typography className="lowerBoxTitleTypoBold">{this.state.bankDetails.account_number}</Typography></Box>
                          </Box>
                          <Box className="lowerBoxSecond">
                            <Box className="lowerBoxTitle"><Typography className="lowerBoxTitleTypo">SWIFT:&nbsp;</Typography></Box>
                            <Box className="lowerBoxTitle"><Typography className="lowerBoxTitleTypoBold">{this.state.bankDetails.swift_code}</Typography></Box>
                          </Box>

                        </Box>
                        <Box><Typography className="lowerBoxIssued"
                        style={{
                          textAlign: this.handleCondition(this.props.i18n?.language === 'ar', 'right', 'left'),
                        }}
                        >{this.handleCondition(this.state.reissue, this.props.i18n?.t('reissue'), this.props.i18n?.t('issuedIn'))} {this.formattedDate}</Typography></Box>

                        <Grid container spacing={0} className="lowerBoxUserDetailsGrid">

                          <Grid item xs={12} sm={12} md={6}>

                            <Box>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">First Party:&nbsp;</Typography></Box>
                                <Box><Typography className="lowerBoxTitleTypoBold">Abdulwahab Abdullah Al Maimani</Typography></Box>
                              </Box>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">Civilian Number:&nbsp;</Typography></Box>
                                <Box><Typography className="lowerBoxTitleTypoBold">7556918</Typography></Box>
                              </Box>
                            </Box>

                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>

                            <Box>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">Second Party:&nbsp;</Typography></Box>
                                <Box><Typography className="ltr-text lowerBoxTitleTypoBold">{this.state.second_party_english}</Typography></Box>
                              </Box>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">Civilian Number:&nbsp;</Typography></Box>
                                <Box><Typography className="lowerBoxTitleTypoBold">
                                  <input
                                    data-test-id="civilianNumber"
                                    id='pdf-content input'
                                    className="customInput"
                                    type="text"
                                    autoComplete="off"
                                    value={this.state.civilian_number}
                                    onChange={(e) => this.handleInputChange(e, 'civilian_number')}
                                    placeholder="Enter civilian number"
                                    lang="ar"
                                    dir="auto"
                                  />

                                </Typography></Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={0} className="lowerBoxUserDetailsArabicGrid">

                          <Grid item xs={12} sm={12} md={6}>

                            <Box style={{ direction: 'rtl' }}>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">الطرف الثاني:&nbsp;</Typography></Box>
                                <Box><Typography className="rtl-text lowerBoxTitleTypoBold">{this.state.second_party_arabic}</Typography></Box>
                              </Box>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">الرقم المدني:&nbsp;</Typography></Box>
                                <Box><Typography className="lowerBoxTitleTypoBold">{this.state.civilian_number}</Typography></Box>
                              </Box>
                            </Box>

                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>

                            <Box style={{ direction: 'rtl' }}>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">الطرف األول:&nbsp;</Typography></Box>
                                <Box><Typography className="lowerBoxTitleTypoBold">عبدالوهاب بن عبدالله الميمني</Typography></Box>
                              </Box>
                              <Box sx={{ display: 'block ruby', [theme.breakpoints.down(1130)]: { display: 'block' }, [theme.breakpoints.down(985)]: { display: 'block ruby' }, [theme.breakpoints.down(400)]: { display: 'block' } }}>
                                <Box><Typography className="lowerBoxTwoTitleTypo">الرقم المدني:&nbsp;</Typography></Box>
                                <Box><Typography className="lowerBoxTitleTypoBold">7556918</Typography></Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        {this.renderErrorBox(this.state.errorBox)}
                        {this.renderErrorBoxSign(this.state.errorBoxSign)}
                        {this.renderErrorBoxForInput(this.state.errors)}
                        <Box id="checkbox" className="checkBoxMain" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                          <Box style={{ marginTop: '1px' }}>
                            <Checkbox

                              data-test-id="setCheckBoxValue"
                              onClick={() =>
                                this.setCheckBoxValue(!this.state.checkedBtn)
                              }
                              checked={this.state.checkedBtn}
                              className="root"
                              disableRipple

                              color="default"
                              checkedIcon={
                                <span className={`icon checkedIcon`} />
                              }
                              onChange={() => {
                                this.setCheckBoxValue(!this.state.checkedBtn);
                                setFieldValue("checkbox", !this.state.checkedBtn);
                              }}

                              icon={<span className="icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          </Box>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px', paddingRight: this.handleCondition(this.props.i18n?.language === 'ar', "12px", "") }}>
                            <Typography className="check_typo_first">
                              {this.handleTranslationChange('iAgree')}
                            </Typography>
                            <Typography data-testid="gotToTermaAndConds" onClick={this.goToTermsAndConds} className="check_typo_second">
                              {this.handleTranslationChange('terms&condition')}
                            </Typography>
                            <Typography className="check_typo_third">
                              {this.handleTranslationChange('readyToSign')}
                            </Typography>

                          </Box>


                        </Box>
                        <Box 
                        style={{display: 'flex', justifyContent: 'space-between', direction: this.handleCondition(this.props.i18n?.language === 'ar' , 'rtl', 'ltr')}}
                        >
                        <button type="submit" style={{ cursor: 'pointer',border:"none",background:"none" }} data-test-id="imgUploadBoxOpen" disabled={this.state.isSigned} >
                          {this.state.isSigned ? 
                          <>
                          <Box className="renderImagePreview">
                            {this.state.document_status !== "pending" ? 
                             <Typography
                             style={{ maxWidth: '360px', maxHeight: '86px', color:"gray", margin:"auto 0" }}
                           >{this.handleTranslationChange('documentSigned')}</Typography>
                            :
                            <img
                              style={{ maxWidth: '360px', maxHeight: '86px' }}
                            />
                          }
                         
                        </Box>
                            <Box style={{marginTop:'8px'}} >
                            <Typography id="e-signature-field" style={{
                              color:"#0F172A",
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '22px',
                              textAlign: 'left',
                            }}>
                            </Typography>
                          </Box>
                          </>
                         :
                          <img className="uploadImgBox"
                          src={this.handleCondition(this.props.i18n?.language === 'ar', imgUploadBoxLargeA,this.state.imgUploadBoxLarge)}
                          alt="img" /> 
                          }
                          
                        </button>
                          <Box>
                          { this.state.isSigned &&
                            <Box className="renderImagePreview">
                                <img
                                style={{ maxWidth: '360px', maxHeight: '86px', marginBottom: '12px' }}
                              />
                            </Box>
                              }
                          </Box>
                        </Box>
                        <div
                          style={{direction: this.handleCondition(this.isArabic, 'rtl', 'ltr'),
                           display:"flex",
                            gap:"10px"}}
                        >
                        <SubmitButton onClick={this.submitContract} 
                        style={{color:"white"}}
                        id="button"
                        disabled={this.state.document_status == "pending"}
                        >
                          {this.handleTranslationChange('agree&submit')}
                        </SubmitButton>
                        {this.state.document_status !== "pending" && 
                          <SubmitButton style={{ marginLeft: "10px"}} onClick={() => window.open(this.state.preview_url, "_blank")} >
                            {this.handleTranslationChange('preview')}
                          </SubmitButton>}
                          </div>
                      </Form>
                    )}
                  </Formik>

            </Box>
          </Box>
        
          <Dialog open={this.state.open} data-test-id="handleClose" onClose={this.handleClose}>
                <DialogContent>
                <DialogBoxWrapper style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
               
                <Box className="dialogBoxMain">
                  <Box>
                    <Typography className="dialogBoxTypoFirst">
                    {this.handleTranslationChange('SuccessfulRequestMessage')}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="dialogBoxTypoSecond">
                    {this.handleTranslationChange('SuccessfulRequestSubMessage')}
                    </Typography>
                  </Box>
               
                </Box>
                <Divider style={{marginTop:'24px'}}></Divider>
                  <Box style={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingRight: '7px',
                    paddingBottom: '12px'

                    }}>
                    <Button className="dialogBox_button">
                      <Typography data-test-id="navigateToDashBoardPage" onClick={this.navigateToDashBoardPage} className="dialogBox_button_typo">
                      {this.handleTranslationChange('backToDashboard')}
                      </Typography>
                    </Button>
                  </Box>
                </DialogBoxWrapper>
                 </DialogContent>
          </Dialog>
          
        </Grid>
      </Grid>}
      </div>
      </div>
      </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const CustomFormWeb = withTranslation()(CustomForm)
const SubmitButton = styled(Button)({
  marginTop:'37px',
  marginBottom:'30px',
  backgroundColor: "#1B4FE4",
  color: "#FFFFFF",
  borderRadius: "8px",
  padding: "16px",
  width:'360px',
  [theme.breakpoints.down('xs')]:{
    marginTop:'16px',
    backgroundColor: "#1B4FE4",
    color: "#FFFFFF",
    borderRadius: "8px",
    padding: "10px 16px",
    width:'169px',
  },
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "& .MuiButton-root" :{
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: "none",
  
  },
  
});
const DialogBoxWrapper = styled(Box)({
 
  "& .dialogBoxMain":{
    display:"flex",
    flexDirection: 'column',
    paddingLeft :'10px',
    paddingTop: '2rem',
  },

  "& .dialogBoxTypoFirst":{
    color:'#0F172A',
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    [theme.breakpoints.down('xs')]:{
      color:'#334155',
      fontFamily: 'DIN Next LT Arabic Bold',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '26px',
    }

  },
  "& .dialogBoxTypoSecond":{
    color:'#1E293B',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    marginTop: '16px',
    [theme.breakpoints.down('xs')]:{
      color:'#1E293B',
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    }

  },
  "& .dialogBox_button":{
    backgroundColor:"#1B4FE4",
    marginTop:'24px'
  },
  "& .dialogBox_button_typo":{
    color:'#FFFFFF',
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform:'none',
    cursor:'pointer'

  },
});

const MainWrapper = styled(Box)(({ theme }) => ({
  
  "& .scrollableContainer": {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '100%',
    marginBottom: '20px',
  },
  "& .renderImagePreview":{
    marginTop:'46px',
    width:'360px',
    height:'86px',
    borderRadius:'8px',
    backgroundColor:'#F1F5F9',
    display:'flex',
    justifyContent:'center',
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px',
      width:'auto',
    },

  },
  "& .uploadImgBox":{
    marginTop:'30px',
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px',
      width:'100%'
    }

  }, 
  "& .mainBox": { 
    padding: '0 135 0 135',
    borderRadius: 0,
    display: "flex",
    // height: "100vh",
    flexDirection: "column",
    [theme.breakpoints.down(985)]:{
      padding: '0 16 0 16',
    }
  },
  "& .innerBox": {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]:{
      marginTop:'16px',
      marginLeft: "16px",
      marginRight: "16px",

    }
  },
  "& .arrowButton": {
    width:'32px',
    height:'32px',
    marginTop:'56px',
    cursor: 'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '&:hover': {
      transition: "0.7s"
    },
  
    [theme.breakpoints.down(985)]:{
      width:'24px',
      height:'24px',
      marginTop:'55px',
      marginLeft:"32px",
      cursor: 'pointer',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      '&:hover': {
        transition: "0.7s"
      },
    }
  },
  "& .title": {
    color:"#000000",
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.005em',
    [theme.breakpoints.down('xs')]:{
      color:"#0F172A",
      fontFamily: 'DIN Next LT Arabic Bold',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '-0.5%',
      marginTop: '8px'

    }
  },
  "& .text":{
    color:"#000000",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'left',
    display:'inline',
    [theme.breakpoints.down("xs")]:{
      color:"#000000",
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      marginTop: '-16px'

    }
  },
  "& .rtl-text": {
    direction: 'rtl',
    unicodeBidi: 'bidi-override',
    textAlign: 'right'
  },
  "& .ltr-text": {
    direction: 'ltr',
    unicodeBidi: 'bidi-override',
    textAlign: 'right'
  },
  "& .check_typo_first":{
    color:'#0F172A',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  "& .check_typo_second":{
    color:'#1948CB',
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer'
  },
 "& .check_typo_third":{
  color:'#0F172A',
  fontFamily: 'DIN Next LT Arabic Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  },
  "& .admin_sinature": {
    color: '#0F172A',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  "& .root": {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  "& .icon": {
    marginLeft: '-9px',
    border:'1px solid #64748B',
    borderRadius: 6,
    width: 20,
    height: 20,
    
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  "& .checkedIcon": {
    backgroundColor: '#132E79',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
 
  "& .MuiInputBase-input":{
    textAlign:'center'
  },
  "& .error_box": {
    borderRadius: "4px",
    borderLeft: "4px solid var(--Status-Red-600, #DC2626)",
    background: "var(--Status-Red-100, #FEE2E2)",
    display: "flex",
    padding: "12px 16px",
    alignItems: "center",
    gap: "8px",
    marginTop: "40px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
  },
  "& .error_box2": {
    borderRadius: "4px",
    background: "var(--Status-Red-100, #FEE2E2)",
    display: "flex",
    padding: "12px 16px",
    alignItems: "center",
    gap: "8px",
    marginTop: "40px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
  },
  "& .error_alert_box": {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
  },
  "& .error_alert_box_style": {
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
    [theme.breakpoints.down(950)]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
    [theme.breakpoints.down(650)]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px",
    },
    [theme.breakpoints.down('xs')]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
    },
  },
  "&.input.valid": {
    borderBottom: '1px solid blue',
  },
  "& .input": {
    borderBottom: '1px solid black',
    borderLeft: '0px',
    borderRight: '0px',
    borderTop: '0px',
    textAlign:'center',
    color:"#000000",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    '&:focus': {
      borderBottom: '1px solid blue',
      backgroundColor: 'none',
      outline: '0'
    },
    [theme.breakpoints.down("xs")]:{
      color:"#1E293B",
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
    }
  },
  
  "& .input.error": {
    borderBottom: '1px solid red'
  },
  
  "& .error" : {
    color: 'red',
  },
  "& .headerBox":{
    display: 'flex',
    alignItems: 'center',
    gap: '202px',
  },
 "& .titleTwo":{
  fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    textAlign: 'center',
    [theme.breakpoints.down(985)]:{
      fontSize: '16px'
    }

 },
 "& .upperTitleBox":{
  display:'flex',
  [theme.breakpoints.between(985,1303)]:{
    display:'block'
  },
  [theme.breakpoints.down(420)]:{
    display:'block'
  }

 },
 "& .upperTitleTypo":{
  fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    [theme.breakpoints.down(985)]:{
      fontSize:'16px'
    }
 },
 "& .upperTitleTypoBold":{
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    [theme.breakpoints.down(985)]:{
      fontSize:'16px'
    }
 },
 "& .lowerBox":{
  border: '2px solid black',
  padding: '8px 24px', 
  marginTop:'16px',
  [theme.breakpoints.down(985)]:{
    marginTop:'0px'
  }

 },
 "& .lowerBoxTitle":{
  display:'flex'
 },
 "& .lowerBoxTitleTypo":{
  fontFamily: 'DIN Next LT Arabic Regular',
  fontSize: '18px',
  lineHeight: '26px',
  textAlign: 'left',
  [theme.breakpoints.down(985)]:{
  display: 'block ruby'
  }


 },
 "& .lowerBoxTitleTypoBold":{
 fontFamily: 'DIN Next LT Arabic Bold',
 fontSize: '18px',
 fontWeight: 700,
 lineHeight: '26px',
 textAlign: 'left',
 [theme.breakpoints.down(985)]:{
  fontSize: '16px',
  }
 },
 "& .lowerBoxIssued":{
  marginTop:'24px',
  fontFamily: 'DIN Next LT Arabic Regular',
  fontSize: '18px',
  lineHeight: '26px',
  [theme.breakpoints.down('xs')]:{
    marginTop:'16px'
  }


 },

 "& .lowerBoxTwo":{
  display:'flex',
  marginTop:'32px',
  gap: '38px',
  [theme.breakpoints.down(985)]:{
    display:'block',
  }
 },
 "& .lowerBoxTwoTitle":{
  display:'block ruby'
 },
 "& .lowerBoxTwoTitleTypo":{
  fontFamily: 'DIN Next LT Arabic Regular',
  fontSize: '24px',
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  [theme.breakpoints.down(985)]:{
    fontSize: '16px',
  }


 },
 "& .lowerBoxTwoTitleTypoBold":{
  fontFamily: 'DIN Next LT Arabic Bold',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
 },
"& .mainHeader":{
  display:'flex',
  justifyContent:'end'
},
"& .addressGrid":{
  alignContent: 'center',
  display: 'flex',
  [theme.breakpoints.down(985)]:{
    justifyContent: 'end',
    paddingRight: '16px'
  }
},
"& .mainGrid":{
  marginTop:'60px',
  [theme.breakpoints.down(985)]:{
    marginTop:'16px',
  }

},
"& .arabicUpperTitleGrid":{
  direction:'rtl'
},
"& .arabicLowerBoxTwo":{
  direction:'rtl',
  display:'flex',
  marginTop:'32px',
  gap: '38px',
  [theme.breakpoints.down(985)]:{
    display:'block',
  }
},
"& .textArabic":{
  color:"#000000",
  fontFamily: 'DIN Next LT Arabic Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  display:'inline',
  [theme.breakpoints.down("xs")]:{
    color:"#1E293B",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: '-16px'

  }
},
 
"& .lowerBoxSecond":{
display:'block ruby',
[theme.breakpoints.down(700)]:{
  display:'block'
}

},
"& .gridIconFirst":{
  [theme.breakpoints.down(400)]:{
  display:'none'
  }
},
"& .upperBox":{
   marginTop:'24px',
   [theme.breakpoints.down('xs')]:{
    marginTop:'5px',

   }
},
"& .contentMainGrid":{
  marginTop:'32px',
  [theme.breakpoints.down('xs')]:{
   marginTop:'16px',

  },
},
"& .lowerBoxUserDetailsGrid":{
  marginTop:'32px',
  [theme.breakpoints.down('xs')]:{
   marginTop:'13px',

  },

},
"& .lowerBoxUserDetailsArabicGrid":{
  marginTop:'32px',
  [theme.breakpoints.down('xs')]:{
   marginTop:'16px',

  },

},
"& .checkBoxMain":{
  display:'flex',
  marginTop: '32px',
  [theme.breakpoints.down('xs')]:{
    marginTop:'16px',
 
   },
},
"& .customInput": {
  marginTop: '6px',
  borderRadius: '4px',
  fontFamily: 'DIN Next LT Arabic Regular',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  padding: '6px 8px'
},

  


}));
// Customizable Area End
