import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import ApiRequest from "../../../components/src/ApiRequest.web";
interface IPaginationData {
  current_page: number;
  next_page: number;
  total_pages: number;
  total_count: number;
}

interface Review {
  userName: string;
  image: string;
  dateOfReview: string;
  reviewDescription: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeAnswerIndex: any;
  landingPageData: any;
  activeHappyInvestors: any;
  freqAskQuestion: any;
  activeLink: any;
  activeProtfolios: any;
  openIntCalculator:boolean;
  newsLetterEmail:string;
  currentLanguage: string;
  paginationData: IPaginationData,
  perPage: string;
  customerReviews: Review[],
  loader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  landingPageApiCalled: any;
  newsLetterId:any;
  apiCallIdReviewList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      activeAnswerIndex: "",
      openIntCalculator:false,
      landingPageData: {},
      freqAskQuestion: [],
      activeHappyInvestors: {
        first: 0,
        second: 1,
        third: 2,
      },
      activeProtfolios: {
        first: 0,
        second: 1,
        third: 2
      },
      activeLink: "home",
      newsLetterEmail:"",
      currentLanguage: this.normalizeLanguage(this.props.i18n?.language) || "en",
      paginationData: {
        current_page: 1,
        next_page: 2,
        total_pages: 0,
        total_count: 0
      },
      perPage: '10',
      customerReviews: [],
      loader: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handlePostNewsLetter(from ,message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.landingPageApiCalled) {
      this.handle(responseJson)
    }
    const apiRequestCallIds = {
      [this.apiCallIdReviewList]: this.getReviewListResponse,

      // Add more API call IDs and handlers as needed
    };
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiError = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apiError);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handle = (responseJson:any) => {
    if(!responseJson.errors) {
      this.setState({
        landingPageData: responseJson?.data?.attributes,
        freqAskQuestion:
          responseJson?.data?.attributes?.frequently_asked_questions.data,
      })
    }
  }
  async componentDidMount() {
    super.componentDidMount();
      this.getLandingPageDetails();
      const pathname = window.location.pathname;
      if(pathname.endsWith("/CustomerReviews")){
        this.getReviewListRequest()
      }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(prevState.currentLanguage !== this.state.currentLanguage) {
      this.getLandingPageDetails()
    }
  }
  handleClick = (index: any) => {
    this.setState({
      activeHappyInvestors: {
        first: this.handleCondition(
          index - 1 < 0,
          this.state.landingPageData?.happy_investors?.data.length - 1,
          index - 1
        ),
        second: index,
        third: this.handleCondition(
          index + 1 >
            this.state.landingPageData?.happy_investors?.data.length - 1,
          0,
          index + 1
        ),
      },
    });
  };
  handleIndex = (index: any) => {
    this.setState({
      activeProtfolios: {
        first: this.handleCondition(
          index - 1 < 0,
          this.state.landingPageData?.portfolio_images?.data.length - 1,
          index - 1
        ),
        second: index,
        third: this.handleCondition(
          index + 1 >
            this.state.landingPageData?.portfolio_images?.data.length - 1,
          0,
          index + 1
        ),
      },
    });
  };
  handlePortfolioClick = (type:string) => {
    const {first, second, third} = this.state.activeProtfolios;
    const totalLength = this.state.landingPageData?.portfolio_images?.data.length;
    if(type === 'next') {
      this.setState({activeProtfolios: {
        first: first === totalLength ? 0 : (first + 1) % totalLength,
        second: second === totalLength ? 0 : (second + 1) % totalLength,
        third: third === totalLength ? 0 : (third + 1) % totalLength
      }})
    }
    if(type === 'prev') {
      this.setState({activeProtfolios: {
        first: (first - 1 + totalLength) % totalLength,
        second: (second - 1 + totalLength) % totalLength,
        third: (third - 1 + totalLength) % totalLength
      }})
    }
  }
  handleRenderJsx = (index: string, type: string) => {
    return this.handleCondition(
      type !== "image",
      this.state.landingPageData?.happy_investors?.data[
        this.state.activeHappyInvestors[index]
      ]?.attributes[type],
      this.state.landingPageData?.happy_investors?.data[
        this.state.activeHappyInvestors[index]
      ]?.attributes[type]?.url
    );
  };
  handleNavigate = (path: string) => {
    this.props.navigation.navigate(path);
  };
  handleActiveLink = (link: any) => {
    this.setState({ activeLink: link });
  };
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  setActiveAnswerIndex(index: any) {
    this.setState({
      activeAnswerIndex: this.state.activeAnswerIndex === index ? "" : index,
    });
  }
  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };
  
  openInteractiveCalculator = () => {
    this.setState({openIntCalculator: !this.state.openIntCalculator});
  }

  displayState = () => {
    this.setState({openIntCalculator: !this.state.openIntCalculator});
  }
  
  getLandingPageDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.landingPageApiCalled = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingpageGetApiEndpint}?language=${this.state.currentLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostNewsLetter = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.newsLetterId) {
      if(!apiResponse.errors) {
        toast.success(apiResponse.message)
        this.setState({newsLetterEmail:""})
      }else{
        toast.error(apiResponse.errors[0].email)
      }
    }
  }

  handleNewsLetterApi =() => {
    const formResponseData = new FormData();
    formResponseData.append("email", this.state.newsLetterEmail);
  const header = {
    contentType: configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  const newsData = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  newsData.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.newsLetterId = newsData.messageId;

  newsData.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.newsLetterEndPoint
  );
  newsData.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formResponseData
  );
  newsData.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(newsData.id, newsData);
};

handleLanguageChange = (lng: string) => {
  const { i18n } = this.props;
  i18n.changeLanguage(lng);
  this.setState({currentLanguage: lng})
}

normalizeLanguage = (lang: string) => {
  if (!lang) return "en";
  return lang.split("-")[0]; 
};


handleNavigateToTC = () => {
  const goToTermsAndCondition = new Message(getName(MessageEnum.NavigationMessage));
  goToTermsAndCondition.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
  goToTermsAndCondition.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(goToTermsAndCondition);
}

// Customer Reviews Fucntion
handlePaginatioChange = (event: any, next: number) => {
  this.setState((prevState) => ({
    paginationData: {
      ...prevState.paginationData,
      current_page: next
    },
  }), this.getReviewListRequest)
}

customerReviews = () => this.navigateTo("CustomerReviews")

navigateTo = (endpoint: string) => {
 const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
 goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
 goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
 this.send(goToContractForm);
}

getReviewListRequest = async () => {
  const { paginationData, perPage } = this.state;
  const header = {
    "Content-Type": configJSON.apiContentType,
  };
  const params = new URLSearchParams();

  if (paginationData.current_page) params.append("page", paginationData?.current_page.toString());
  if (perPage) params.append("per_page", perPage);

  const requestMessage = ApiRequest({
    header: header,
    endPoint: `${configJSON.customerReviewsEndpoint}?${params.toString()}`,
    method: "GET",
  });

  this.apiCallIdReviewList = requestMessage.messageId;

  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({loader: true})
};

getReviewListResponse = (responseJson: any, errorResponse: any) => {
  // Clear previous data in case of error
  if (errorResponse) {
    toast.error("Sorry! Sormething went wrong.");
    return;
  }

  // Check if response contains data
  if (responseJson && responseJson.data.length > 0) {
      const paginationData: IPaginationData = {
        current_page: responseJson.meta?.current_page || 0,
        next_page: responseJson.meta?.next_page || 0,
        total_pages: responseJson.meta?.total_pages || 0,
        total_count: responseJson.meta?.total_count || 0,
      };

      const customerReviews: Review[] = responseJson.data.map((review: any) => ({
        userName: `${review.attributes.full_name}`,
        image: review.attributes.profile_image ?? "link",
        dateOfReview: review.attributes.comment_date || "",
        reviewDescription: review.attributes.comment || "",
      }));

      // Update the state with the parsed data
      this.setState({
        paginationData,
        customerReviews,
      });
  } else {
    toast.error("Invalid response structure:", responseJson);
    this.resetCustomReview()
  }
  this.setState({loader: false})
};

resetCustomReview = () => {
  this.setState({
    paginationData: {
      current_page: 0,
      next_page: 0,
      total_pages: 0,
      total_count: 0,
    },
    customerReviews: [],
  });
}

navigateToLandingPage = async ()=>{
  const goToDashboard = new Message(getName(MessageEnum.NavigationMessage));
  goToDashboard.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
  goToDashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(goToDashboard);
}

  // Customizable Area End
}
