Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.notificationData = "bx_block_notifications/notifications";
exports.totalInvest = "bx_block_dashboard/dashboard_calculations/total_investments";
exports.totalProfit = "bx_block_dashboard/dashboard_calculations/total_profits";
exports.totalReturns = "bx_block_dashboard/dashboard_calculations/total_returns";
exports.avarageReturns = "bx_block_dashboard/dashboard_calculations/average_returns";
exports.currentBalance = "bx_block_dashboard/dashboard_calculations/get_current_balance";
exports.activities = "bx_block_dashboard/dashboard_calculations/account_activity?";
exports.dashboardGraph = "bx_block_dashboard/dashboard_calculations/dashboard_reports_graph?";
exports.MONTHS = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
// Customizable Area End